@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #f8f8f8;

  scroll-behavior: smooth;
}

@font-face {
  font-family: "HeadingNowBook";
  src: url(assets/fonts/HeadingNowBook.otf) format("opentype");
}

* {
  font-family: "HeadingNowBook";
}

.h-2px {
  height: 2px;
}

.w-2full {
  width: 200%;
}

.-top-9px {
  top: -9px;
}

.-left-10px {
  left: -10px;
}

.bg-event {
  background-color: #00C98B;
}


.bg-offer {
  background-color: #FFD400;
}